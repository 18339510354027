import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import "../assets/css/album.css";
import { NavLink } from "react-router-dom";
import MetaTags from 'react-meta-tags';

const Album = () => {
  const albumcontainer = [
    {
      image: require("../assets/images/album_img1.png"),
      title: "Wedding",
      subtitle: "Mark + Taniya",
    },
    {
      image: require("../assets/images/album_img2.png"),
      title: "Wedding",
      subtitle: "Shankar + Priya",
    },
    {
      image: require("../assets/images/album_img3.png"),
      title: "Wedding",
      subtitle: "Kim & John 50th anniversary",
    },
    {
      image: require("../assets/images/album_img4.png"),
      title: "Religious Gathering",
      subtitle: "Dandiya Night",
    },
  ];

  return (
    <div className="album_main_container">
      <MetaTags>
        <title>Events Album at Gayatri Vihar Sagar, Bangalore</title>
        <meta name="description" content="Gayatri Vihar's stunning events captured by the best photographers. " />
        <meta property="og:title" content="Events Album at Gayatri Vihar Sagar, Bangalore" />
      </MetaTags>
      <div className="album_hero_container">
        <div className="album_hero_content">
          <h3 className="title">Event Albums</h3>
          <p className="subtitle">
            Gayatri Vihar's stunning events captured by the best photographers
          </p>
        </div>
      </div>
      <Grid container className="album_container">
        {albumcontainer.map((item) => {
          const { subtitle } = item;
          return (
            <Grid item lg={5.5} className="album_item">
              <NavLink to="/gallery/album/album-photos">
                <img src={item.image} alt="album image" />
              </NavLink>
              <p className="album_title">{item.title}</p>
              <p className="album_subtitle">{item.subtitle}</p>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Album;
