import React, { useEffect } from "react";
import "../assets/css/VirtualTour.css";
import { Grid } from "@mui/material";
import MetaTags from 'react-meta-tags';

function VirtualTour() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="virual_tour">
      <MetaTags>
        <title>Gayatri Vihar Sagar Wedding Venue Virtual Tour</title>
        <meta name="description" content="Take a look at our virtual tour video to explore about the venue." />
        <meta property="og:title" content="Gayatri Vihar Sagar Wedding Venue Virtual Tour" />
      </MetaTags>
      <div className="Virtual_div_header">
        <p className="Virtual_div_hdr_p1">Virtually explore Gayatri Vihar</p>
        <p className="Virtual_div_hdr_p2">Virtual Tour</p>
      </div>

      <div container className="virtual_div_cont">
        <Grid container className="virtual_grid_cont">
          <Grid item lg={12} xs={12} className="virtual div_item">
            <a href="http://bit.ly/GayatriViharSagar3DTour" target="_blank">
              <div className="virtual_item_div"></div>
            </a>
            <div className="virtual_grid_item">Gayatri Vihar Hall</div>
          </Grid>

          <Grid item lg={12} xs={12} className="virtual div_item">
            <a href="http://bit.ly/GayatriViharMainHall3DTour" target="_blank">
              {" "}
              <div className="virtual_item_div2"></div>
            </a>
            <div className="virtual_grid_item">Gayatri Vihar Garden</div>
          </Grid>
          <Grid item lg={12} xs={12} className="virtual div_item">
            <a
              href="http://bit.ly/GayatriViharSagarGarden3DTour"
              target="_blank"
            >
              <div className="virtual_item_div3"></div>
            </a>
            <div className="virtual_grid_item">Gayatri Vihar Main Hall</div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default VirtualTour;
