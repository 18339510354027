import React, { useEffect, useState, useRef } from "react";
import "../assets/css/navbar.css";
import { Grid } from "@mui/material";
import { ReactComponent as GV_logo } from "../../src/assets/images/GV_logo.svg";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { AiOutlineUp } from "react-icons/ai";
import { AiOutlineDown } from "react-icons/ai";

import { MdClose, MdClosedCaptionOff } from "react-icons/md";
import { HiOutlineMenu } from "react-icons/hi";
import { Fade } from "react-reveal";
import { IoIosArrowDown } from "react-icons/io";

export default function Navbar() {
  const [tnavbar, setTnavbar] = useState(false);
  const [mobTnavbar, setMobTnavbar] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [about, setAbout] = useState(false);
  const [wedding, setWedding] = useState(false);
  const [gallery, setGallery] = useState(false);
  const [closeAnime, setCloseAnime] = useState(false);

  const Location = useLocation();

  const Tnavbar = [
    "/collaboration",
    "/gallery/images",
    "/BlogsArticle",
    "/gallery/virtual-tour",
    "/featured-articles",
  ];

  const [open, setOpen] = React.useState(false);
  const [opens, setOpens] = React.useState(false);
  const [openEvent, setOpenEvent] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
    setOpens(false);
    setOpenEvent(false);
  };
  const handleOpens = () => {
    setOpens(!opens);
    setOpen(false);
    setOpenEvent(false);
  };
  const handleOpenEvt = () => {
    setOpenEvent(!openEvent);
    setOpen(false);
    setOpens(false);
  };

  let dropdownRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!dropdownRef.current.contains(e.target)) {
        setOpen(false);
        setOpens(false);
        setOpenEvent(false);
      }
      // console.log(dropdownRef.current);
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  window.addEventListener("scroll", (event) => {
    let scroll = window.scrollY;
    if (scroll > 100) {
      setTnavbar(true);
      setMobTnavbar(true);
    } else {
      setTnavbar(false);
      setMobTnavbar(false);
    }
  });

  const handleCloseMenu = () => {
    setOpen(false);
    setOpens(false);
    setOpenEvent(false);
  };

  return (
    <div>
      <div
        className={`${
          !tnavbar
            ? !Tnavbar.includes(Location.pathname)
              ? "navbar"
              : "Cnavbar"
            : "tnavbar"
        }`}
      >
        <div container className="navbar_container" ref={dropdownRef}>
          <Grid container className="navbar_top">
            <Grid item lg={2}>
              <NavLink to="/">
                <GV_logo className="gv_logo" />
              </NavLink>
            </Grid>

            <Grid item className="nav_bottom" lg={10}>
              <NavLink to="home" onClick={handleCloseMenu}>
                HOME
              </NavLink>

              <div className="div_event" onClick={handleOpens}>
                {" "}
                <div className="gallery_div">
                  <span className="menu_main_item">ABOUT</span>
                  <IoIosArrowDown
                    className={`${opens ? "up_arrow_abt" : "down_arrow_abt"}`}
                  />
                </div>
                {opens ? (
                  <div className="menu">
                    <NavLink to="/our-legacy" className="btn">
                      Our Legacy
                    </NavLink>
                    <NavLink to="/featured-articles" className="btn">
                      Featured Article
                    </NavLink>
                  </div>
                ) : null}
              </div>

              <div className="div_event">
                {" "}
                <div onClick={handleOpenEvt} className="gallery_div">
                  <span className="menu_main_item">EVENTS</span>
                  <IoIosArrowDown
                    className={`${
                      openEvent ? "up_arrow_wed" : "down_arrow_wed"
                    }`}
                  />
                </div>
                {openEvent ? (
                  <div className="menu">
                    <NavLink
                      to="/events/wedding-events"
                      className="btn"
                      onClick={handleCloseMenu}
                    >
                      Weddings
                    </NavLink>

                    <NavLink
                      to="/events/corporate-events"
                      className="btn"
                      onClick={handleCloseMenu}
                    >
                      Corporate Events
                    </NavLink>
                    <NavLink
                      to="/events/birthday-events"
                      className="btn"
                      onClick={handleCloseMenu}
                    >
                      Birthday’s
                    </NavLink>
                    <NavLink
                      to="/events/religious-gatherings"
                      className="btn"
                      onClick={handleCloseMenu}
                    >
                      Religious Events
                    </NavLink>
                    <NavLink
                      to="/events/political-events"
                      className="btn"
                      onClick={handleCloseMenu}
                    >
                      Political Events
                    </NavLink>
                    <NavLink
                      to="/events/anniversaries"
                      className="btn"
                      onClick={handleCloseMenu}
                    >
                      Anniversaries
                    </NavLink>
                  </div>
                ) : null}
              </div>

              <NavLink to="/catering" onClick={handleCloseMenu}>
                OUTDOOR CATERING
              </NavLink>

              <div className="div_event" onClick={handleOpen}>
                {" "}
                <div className="gallery_div">
                  <span className="menu_main_item">GALLERY</span>

                  <IoIosArrowDown
                    className={`${open ? "up_arrow_gal" : "down_arrow_gal"}`}
                  />
                </div>
                {open ? (
                  <div className="menu">
                    <NavLink to="/gallery/images" className="btn">
                      Images
                    </NavLink>
                    <NavLink to="/gallery/album" className="btn">
                      Album
                    </NavLink>
                    <NavLink to="/gallery/virtual-tour" className="btn">
                      Virtual Tour
                    </NavLink>
                  </div>
                ) : null}
              </div>

              <NavLink to="/sagar-events" onClick={handleCloseMenu}>
                SAGAR EVENTS
              </NavLink>

              <NavLink to="/collaboration" onClick={handleCloseMenu}>
                COLLABORATION
              </NavLink>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="mobile_navbar">
        {sidebarOpen ? (
          <div
            className={`${closeAnime ? "close_anime" : "mobile_navbar_opened"}`}
          >
            <div
              className="close_sidebar_btn"
              onClick={() => {
                setCloseAnime(true);
                setTimeout(() => {
                  setSidebarOpen(false);
                }, 500);
              }}
            >
              <MdClose />
            </div>
            <div className="mobile_ul_items_div">
              <ul className="sidebar_main_list">
                <Fade cascade delay={300}>
                  <li
                    onClick={() => {
                      setSidebarOpen(false);
                    }}
                  >
                    <NavLink to="/home">HOME</NavLink>
                  </li>
                </Fade>
                <Fade cascade delay={300}>
                  <li>
                    <p
                      onClick={() => {
                        setAbout(!about);
                      }}
                    >
                      <span>ABOUT</span>{" "}
                      <IoIosArrowDown
                        className={`${
                          about ? "up_arrow_abt" : "down_arrow_abt"
                        }`}
                      />{" "}
                    </p>
                    {about ? (
                      <ul className="nested_list">
                        <li
                          onClick={() => {
                            setSidebarOpen(false);
                          }}
                        >
                          <NavLink to="/our-legacy">OUR LEGACY</NavLink>
                        </li>
                        <li
                          onClick={() => {
                            setSidebarOpen(false);
                          }}
                        >
                          <NavLink to="/featured-articles">
                            FEATURED ARTICLE
                          </NavLink>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                </Fade>
                <Fade cascade delay={300}>
                  <li>
                    <p
                      onClick={() => {
                        setWedding(!wedding);
                      }}
                    >
                      <span>EVENTS</span>{" "}
                      <IoIosArrowDown
                        className={`${
                          wedding ? "up_arrow_wed" : "down_arrow_wed"
                        }`}
                      />{" "}
                    </p>

                    {wedding ? (
                      <ul className="nested_list">
                        <li
                          onClick={() => {
                            setSidebarOpen(false);
                          }}
                        >
                          <NavLink to="/events/wedding-events">
                            Weddings
                          </NavLink>
                        </li>

                        <li
                          onClick={() => {
                            setSidebarOpen(false);
                          }}
                        >
                          <NavLink to="/events/corporate-events">
                            Corporate Events
                          </NavLink>
                        </li>
                        <li
                          onClick={() => {
                            setSidebarOpen(false);
                          }}
                        >
                          <NavLink to="/events/birthday-events">
                            Birthday’s
                          </NavLink>
                        </li>

                        <li
                          onClick={() => {
                            setSidebarOpen(false);
                          }}
                        >
                          <NavLink to="/events/religious-gatherings">
                            {" "}
                            Religious Events
                          </NavLink>
                        </li>
                        <li
                          onClick={() => {
                            setSidebarOpen(false);
                          }}
                        >
                          <NavLink to="/events/political-events">
                            Political Events
                          </NavLink>
                        </li>
                        <li
                          onClick={() => {
                            setSidebarOpen(false);
                          }}
                        >
                          <NavLink to="/events/anniversaries">
                            Anniversaries
                          </NavLink>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                </Fade>
                <Fade cascade delay={300}>
                  <li
                    onClick={() => {
                      setSidebarOpen(false);
                    }}
                  >
                    <NavLink to="/catering">OUTDOOR CATERING</NavLink>
                  </li>
                </Fade>
                <Fade cascade delay={300}>
                  <li>
                    <p
                      onClick={() => {
                        setGallery(!gallery);
                      }}
                    >
                      <span>GALLERY</span>{" "}
                      <IoIosArrowDown
                        className={`${
                          gallery ? "up_arrow_gal" : "down_arrow_gal"
                        }`}
                      />{" "}
                    </p>
                    {gallery ? (
                      <ul className="nested_list">
                        <li
                          onClick={() => {
                            setSidebarOpen(false);
                          }}
                        >
                          <NavLink to="/gallery/images">Images</NavLink>
                        </li>
                        <li
                          onClick={() => {
                            setSidebarOpen(false);
                          }}
                        >
                          <NavLink to="/gallery/virtual-tour">
                            Virtual Tour
                          </NavLink>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                </Fade>
                <Fade bottom cascade delay={300}>
                  <li
                    onClick={() => {
                      setSidebarOpen(false);
                    }}
                  >
                    <NavLink to="/sagar-events">SAGAR EVENTS</NavLink>
                  </li>
                </Fade>
                <Fade bottom cascade delay={300}>
                  <li
                    onClick={() => {
                      setSidebarOpen(false);
                    }}
                  >
                    <NavLink to="/collaboration">COLLABORATION</NavLink>
                  </li>
                </Fade>
              </ul>
            </div>
          </div>
        ) : (
          <Fade cascade duration={400}>
            <div
              className={`${
                mobTnavbar
                  ? "mobile_navbar_closed mob_tnavbar_closed"
                  : "mobile_navbar_closed"
              }`}
            >
              <NavLink to="/">
                <img
                  src={require("../assets/images/GV_logo.png")}
                  alt="logo"
                  className="mobile_logo"
                />
              </NavLink>{" "}
              <HiOutlineMenu
                onClick={() => {
                  setSidebarOpen(true);
                  setCloseAnime(false);
                }}
                className={`${
                  Tnavbar.includes(Location.pathname)
                    ? "t_hamburger"
                    : "hamburger"
                }`}
              />
            </div>
          </Fade>
        )}
      </div>
    </div>
  );
}
