import React, { useEffect, useState } from "react";
import "../assets/css/home.css";
import { FiArrowDownRight } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { Grid } from "@mui/material";
import { ReactComponent as Left_arrow } from "../assets/images/left_arrow.svg";
import { ReactComponent as Right_arrow } from "../assets/images/right_arrow.svg";
import { ReactComponent as Caters_icon } from "../assets/images/caters_icon.svg";
import { ReactComponent as End_line } from "../assets/images/end_line.svg";
import { ReactComponent as Readmore_arrow } from "../assets/images/read_more_arrow.svg";
import { ReactComponent as Cuisine_arrow } from "../assets/images/cuisine_arrow.svg";
import $ from "jquery";
import MetaTags from 'react-meta-tags';

function Home({ setEnquire }) {
  const [changingIndex, setChangingIndex] = useState(0);
  const [animate, setAnimate] = useState(false);

  const wedding = [
    {
      image: require("../assets/images/home_image2.png"),
      title: "Wedding",
      description:
        " Planning your dream wedding? We provide the right setting to make it perfect. With both indoor and outdoor areas suitable to host all your guests, and make sure your dream day happens without a hitch.",
      more: "LEARN MORE",
      path: "/events/wedding-events",
    },

    {
      image: require("../assets/images/home_corporate_img.png"),
      title: "Corporate Events",
      description:
        " Organizing an office party? Corporate Getaway? Gala? Banquet? There's plenty of room for everything with us. Going for the professional look or an informal vibe, we will set it up to suit your brand image. ",
      more: "LEARN MORE",
      path: "/events/corporate-events",
    },
    {
      image: require("../assets/images/home_birthday_img.png"),
      title: "Birthday Parties",
      description:
        " Throwing a surprise birthday party? Planning a grand one for your close one? Need a small space or a big one? We have it all. ",
      more: "LEARN MORE",
      path: "/events/birthday-events",
    },
    {
      image: require("../assets/images/home_religious_img.png"),
      title: "Religious Gatherings ",
      description:
        " Be it a celebration or a festival, whatever it is, we’ll help you host it to perfection. With large outdoor spaces and intimate indoor spaces, we can set the place up to match your customs and traditions. ",
      more: "LEARN MORE",
      path: "/events/religious-gatherings",
    },
    {
      image: require("../assets/images/home_political_img.png"),
      title: "Political Events",
      description:
        "Hosting a fundraiser? Celebration party? Important meeting? We’ve got the space. From decorating the space in your party colours to hosting everyone, be it a large crowd or a small one.  ",
      more: "LEARN MORE",
      path: "/events/political-events",
    },
    {
      image: require("../assets/images/home_anniversary_img.png"),
      title: "Anniversaries ",
      description:
        "Celebrating an anniversary? Planning a surprise for your loved one? Worry not. We have it handled, from setting up a dream venue, to welcoming and serving your guests.",
      more: "LEARN MORE",
      path: "/events/anniversaries",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLeftArrowClick = () => {
    if (changingIndex === 0) {
      setChangingIndex(wedding.length - 1);
    } else {
      setChangingIndex(changingIndex - 1);
    }

    setAnimate(true);
  };

  const handleRightArrowClick = () => {
    if (changingIndex === wedding.length - 1) {
      setChangingIndex(0);
    } else {
      setChangingIndex(changingIndex + 1);
    }
    setAnimate(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setAnimate(false);
    }, 300);
  }, [handleLeftArrowClick, handleRightArrowClick]);

  return (
    <div className="home">
      <MetaTags>
        <title>Gayatri Vihar Sagar - Luxury Wedding Venue & Banquet Hall in Bangalore!</title>
        <meta name="description" content="The perfect destination for all sorts of events. Located at the Heart of Bangalore, filled with the ambience of Royalty in Palace grounds." />
        <meta property="og:title" content="Gayatri Vihar Sagar - Luxury Wedding Venue & Banquet Hall in Bangalore!" />
      </MetaTags>
      <div className="home_hero_container">
        <div className="home_hero_content">
          <h3 className="gayathri-vihar">Gayatri Vihar</h3>
          <h3 className="main_title">
            The Venue of Your Dreams, For Your Dreams to Come True{" "}
          </h3>
          <span>
            <button className="button">
              <span
                className="enquire_text"
                onClick={() => {
                  setEnquire(true);
                }}
              >
                ENQUIRE NOW
              </span>
            </button>
            <p
              className="learn_more"
              onClick={() => {
                $("html,body").animate(
                  {
                    scrollTop: $("#why_us_section").offset().top - 150,
                  },
                  10
                );
              }}
            >
              LEARN MORE <FiArrowDownRight className="learn_more_arrow" />
            </p>
          </span>
        </div>
      </div>
      <Grid container className="why_us_section" id="why_us_section">
        <Grid item lg={6}>
          <p className="why_us_text">WHY US?</p>
          <p className="subtitle">Everything Under One Roof</p>
          <p className="why_us_para">
            The perfect destination for all kinds of events. Be it large
            weddings or small ones, corporate events or private parties, we have
            the perfect venues to host all of these at Gayathri Vihar. The best
            part, we have cuisines from around the world to make it more
            memorable. From contemporary Indian to lavish western, anything you
            want catered to your taste.
          </p>
        </Grid>
        <Grid item lg={6} className="right">
          <img
            src={require("../assets/images/home_image1.png")}
            alt="event_image"
          />
          <h3 className="text">For a Perfect Day</h3>
        </Grid>
      </Grid>

      <div className="home_wedding_container">
        <div container className="home_wedding">
          <div className="left">
            <img
              src={wedding[changingIndex].image}
              alt="wedding_image"
              className={`${animate && " animate"}`}
            />
          </div>
          <div className="right">
            <div className="outer_div">
              <div className="inner_div">
                <div className="content">
                  <p
                    className={`${
                      animate ? "subtitle animate animate_text" : "subtitle"
                    }`}
                  >
                    {wedding[changingIndex].title}
                  </p>
                  <p
                    className={`${
                      animate ? "para animate animate_text" : "para"
                    }`}
                  >
                    {wedding[changingIndex].description}
                  </p>
                  <NavLink
                    to={`${wedding[changingIndex].path}`}
                    className={`${
                      animate ? "learn_more animate animate_text" : "learn_more"
                    }`}
                  >
                    {wedding[changingIndex].more}
                  </NavLink>

                  <div className="arrows">
                    <Left_arrow
                      className="left_arrow"
                      onClick={handleLeftArrowClick}
                    />
                    <span className="date">
                      {changingIndex + 1 < 10 ? "0" : ""}
                      {changingIndex + 1} / {wedding.length < 10 ? "0" : ""}
                      {wedding.length}
                    </span>
                    <Right_arrow
                      className="right_arrow"
                      onClick={handleRightArrowClick}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cuisines">
        <div className="cuisines_main_container">
          <Caters_icon className="caters_icon" />
          <p className="subtitle">Sagar Caterers</p>
          <p className="para">
            Bringing you flavours from around the world, for the perfect day. Be
            it a small intimate party or a grand event, we guarantee to bring
            you the best experience.
          </p>
          <h3>Our Cuisines</h3>
          <div className="cuisines_container_home">
            <div className="cuisine_div">
              <NavLink to="/indian-cuisine" className="cuisine">
                <img
                  src={require("../assets/images/cater_india_image.png")}
                  alt="Indian Cuisine"
                />
                <p>
                  <span>INDIAN</span>{" "}
                  <span>
                    <Cuisine_arrow className="cuisine_arrow" />
                  </span>
                </p>
              </NavLink>{" "}
            </div>
            <div className="cuisine_div">
              <NavLink to="/chinese-cuisine" className="cuisine">
                <img
                  src={require("../assets/images/cater_chinese_image.png")}
                  alt="Chinese Cuisine"
                />

                <p>
                  <span>CHINESE</span>{" "}
                  <span>
                    <Cuisine_arrow className="cuisine_arrow" />
                  </span>
                </p>
              </NavLink>{" "}
            </div>
            <div className="cuisine_div">
              <NavLink to="/western-cuisine" className="cuisine">
                <img
                  src={require("../assets/images/cater_western_image.png")}
                  alt="Western Cuisine"
                />

                <p>
                  <span>WESTERN</span>{" "}
                  <span>
                    <Cuisine_arrow className="cuisine_arrow" />
                  </span>
                </p>
              </NavLink>
            </div>
          </div>
          <NavLink to="/catering" className="cuisines_know_more button ">
            KNOW MORE
          </NavLink>
        </div>
      </div>
      <div className="home_gallery">
        <p className="subtitle">Gayatri Vihar Gallery</p>
        <h3>The Collection</h3>
        <div className="marquee">
          <ul className="marquee-content">
            <li>
              <img
                src={require("../assets/images/g_image1.png")}
                alt="gallery_image"
              />
            </li>
            <li>
              <img
                src={require("../assets/images/g_image2.png")}
                alt="gallery_image"
              />
            </li>
            <li>
              <img
                src={require("../assets/images/g_image3.png")}
                alt="gallery_image"
              />
            </li>
            <li>
              <img
                src={require("../assets/images/g_image4.png")}
                alt="gallery_image"
              />
            </li>
            <li>
              <img
                src={require("../assets/images/g_image1.png")}
                alt="gallery_image"
              />
            </li>
            <li>
              <img
                src={require("../assets/images/g_image2.png")}
                alt="gallery_image"
              />
            </li>
            <li>
              <img
                src={require("../assets/images/g_image3.png")}
                alt="gallery_image"
              />
            </li>
            <li>
              <img
                src={require("../assets/images/g_image4.png")}
                alt="gallery_image"
              />
            </li>
            <li>
              <img
                src={require("../assets/images/g_image1.png")}
                alt="gallery_image"
              />
            </li>
            <li>
              <img
                src={require("../assets/images/g_image2.png")}
                alt="gallery_image"
              />
            </li>
          </ul>
        </div>
        <NavLink to="/gallery/images" className="view_all">
          VIEW ALL
        </NavLink>
      </div>
      <p className="featured_articles_subtitle">Featured Articles</p>
      <div container className="featured_articles">
        <div className="article">
          <div className="article_image">
            <img
              src={require("../assets/images/article_image1.png")}
              alt="article_image"
            />
          </div>
          <div className="article_content">
            {" "}
            <p className="article_title">
              Bengalureans get into festive mode at Gayatri Vihar{" "}
            </p>
            <p className="para">
              Nearly 400 members of Federation of Indian Chambers of Commerce
              and Industry (FICCI) FLO Bengaluru gathered at Gayatri Vihar
              Hall...
            </p>
            <End_line className="end_line" />
            <p className="read_more">
              <span> Read More</span>{" "}
              <Readmore_arrow className="read_more_arrow" />{" "}
            </p>
          </div>
        </div>
        <div className="article">
          <div className="article_image">
            <img
              src={require("../assets/images/article_image2.png")}
              alt="article_image"
            />
          </div>
          <div className="article_content">
            {" "}
            <p className="article_title">
              Bengalureans get into festive mode at Gayatri Vihar{" "}
            </p>
            <p className="para">
              Nearly 400 members of Federation of Indian Chambers of Commerce
              and Industry (FICCI) FLO Bengaluru gathered at Gayatri Vihar
              Hall...
            </p>
            <End_line className="end_line" />
            <p className="read_more">
              <span> Read More</span>{" "}
              <Readmore_arrow className="read_more_arrow" />{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
