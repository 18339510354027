import React, { useState, useEffect } from "react";
import "../assets/css/contact-catering.css";
import { Grid } from "@mui/material";
import { Form, FormGroup, Input, Label } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import MetaTags from 'react-meta-tags';

function ContactHall() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formValues, setformValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    number: "",
    nameofevent: "",
    date: "",
  });
  const [formErrors, setformErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validated = validate(formValues);

    setformErrors(validated);

    if (Object.keys(validated)?.length === 0) {
      axios
        .post("http://localhost:5000/submit", formValues)
        .then((response) => console.log(response.data));

      setformValues({
        firstname: "",
        lastname: "",
        email: "",
        number: "",
        nameofevent: "",
        date: "",
      });
    }
  };

  var date = new Date();
  var minDate =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

  const validate = (values) => {
    const errors = {};
    const emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values?.firstname) {
      errors.firstname = " First Name is required";
    }

    if (!values?.lastname) {
      errors.lastname = " Last Name is required";
    }

    if (!values?.email) {
      errors.email = "Email is required";
    } else if (!emailregex.test(values.email)) {
      errors.email = "email  format 'abcd@domainname.com'";
    }

    if (!values?.number) {
      errors.number = "  Mobile Number is required";
    } else if (values?.number.length < 10) {
      errors.number = "Number should be 10 digits";
    } else if (values?.number.length > 10) {
      errors.number = "Number should be 10 digits";
    }

    if (!values?.nameofevent) {
      errors.nameofevent = "Event is required";
    }

    if (!values?.date) {
      errors.date = "Date is required";
    }

    return errors;
  };

  return (
    <div className="contact_hall">
      <MetaTags>
        <title>Contact Gayatri Vihar Sagar for your Queries</title>
        <meta name="description" content="Get in Touch With Gayatri Vihar Sagar For Bookings and Enquiries." />
        <meta property="og:title" content="Contact Gayatri Vihar Sagar for your Queries" />
      </MetaTags>
      <div className="contact_hall_hero_banner">
        <div className="content">
          <p className="contact_catering_hero_title">Help Us Your Event</p>
          <h3 className="contact_catering_hero_subtitle">
            We can't wait to hear from you
          </h3>
        </div>
      </div>

      <Grid container className="contact_container">
        <Grid item lg={7} xs={12} className="left">
          <p className="title">Contact Us</p>
          <p className="subtitle">Gayatri Vihar</p>
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item lg={6} xs={12} className="input_fields">
                <FormGroup floating>
                  <Input
                    type="text"
                    name="firstname"
                    placeholder="Your First Name"
                    id="input_field"
                    className="firstname"
                    value={formValues?.firstname || ""}
                    onChange={handleChange}
                  />
                  <p className="contact_errors">{formErrors?.firstname}</p>
                  <Label className="label" for="first_name">
                    First Name*
                  </Label>
                </FormGroup>
              </Grid>

              <Grid item lg={6} xs={12} className="input_fields">
                <FormGroup floating>
                  <Input
                    type="text"
                    name="lastname"
                    placeholder="Your Last Name"
                    id="input_field"
                    className="lastname"
                    value={formValues?.lastname || ""}
                    onChange={handleChange}
                  />
                  <p className="contact_errors">{formErrors?.lastname}</p>
                  <Label className="label" for="last_name">
                    Last Name*
                  </Label>
                </FormGroup>
              </Grid>
              <Grid item lg={6} xs={12} className="input_fields">
                <FormGroup floating>
                  <Input
                    type="number"
                    arrows={false}
                    name="number"
                    placeholder="Your 10 digits Mobile Number"
                    id="input_field"
                    className="number"
                    value={formValues?.number || ""}
                    onChange={handleChange}
                  />
                  <p className="contact_errors">{formErrors?.number}</p>
                  <Label className="label" for="contact_name">
                    Contact Number*
                  </Label>
                </FormGroup>
              </Grid>
              <Grid item lg={6} xs={12} className="input_fields">
                <FormGroup floating>
                  <Input
                    type="text"
                    name="email"
                    placeholder="Your Email"
                    id="input_field"
                    className="email"
                    value={formValues?.email || ""}
                    onChange={handleChange}
                  />
                  <p className="contact_errors">{formErrors?.email}</p>
                  <Label className="label" for="first_name">
                    Mail ID
                  </Label>
                </FormGroup>
              </Grid>
              <Grid item lg={6} xs={12} className="input_fields">
                <FormGroup floating>
                  <Input
                    type="text"
                    name="nameofevent"
                    placeholder="Type of Event"
                    id="input_field"
                    className="event"
                    value={formValues?.nameofevent || ""}
                    onChange={handleChange}
                  />
                  <p className="contact_errors">{formErrors.nameofevent}</p>

                  <Label className="label" for="first_name">
                    Type of Event*
                  </Label>
                </FormGroup>
              </Grid>
              <Grid item lg={6} xs={12} className="input_fields">
                <FormGroup floating>
                  <Input
                    type="date"
                    name="date"
                    placeholder="Date"
                    id="input_field"
                    className="date"
                    min={minDate}
                    value={formValues?.date || ""}
                    onChange={handleChange}
                  />
                  <p className="contact_errors">{formErrors?.date}</p>

                  <Label className="label" for="date">
                    Date of Event*
                  </Label>
                </FormGroup>
              </Grid>
              <Grid item lg={12} xs={12} className="message_title">
                Message
              </Grid>
              <Grid item lg={12} xs={12} className="input_fields">
                <textarea
                  className="input_msg_field"
                  name="first_name"
                  placeholder="Tell us more about your event"
                  type="text"
                />
              </Grid>

              <button className="submit_btn">SUBMIT</button>
            </Grid>
          </Form>
        </Grid>

        <Grid item lg={5} className="right">
          <p className="find_text">Find us on</p>
          <p className="address">
            Gayatri Vihar Sagar, Gate No.4, Palace Grounds, Near Mekhri Circle,
            Bengaluru, Karnataka 560080
          </p>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.4124802487713!2d77.58357021399598!3d13.009384417569947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae164af13335f9%3A0xfc2dd0c08440c301!2sGayatri%20Vihar%20Sagar%20-%20Palace%20Ground%20Bengaluru!5e0!3m2!1sen!2sin!4v1664274085190!5m2!1sen!2sin"
              width="100%"
              height="650"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              className="frame_map1"
            ></iframe>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ContactHall;
