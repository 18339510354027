import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import "../assets/css/wedding-events.css";
import { NavLink } from "react-router-dom";
import MetaTags from 'react-meta-tags';

function PoliticalEvents() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="wedding_events_conatiner">
      <MetaTags>
        <title>Political Event Venue in Bangalore - Gayathri Vihar Sagar</title>
        <meta name="description" content="With big venues to hold your supporters by the thousand, Gayatri Vihar is equipped to handle crowds of any size to ensure that your event happens without a hitch." />
        <meta property="og:title" content="Political Event Venue in Bangalore - Gayathri Vihar Sagar" />
      </MetaTags>
      <div className="political_hero_container hero_container">
        <p className="hero_content">Political Events </p>
      </div>
      <div className="section_1">
        <p className="sec1_subtitle">Why Gayatri Vihar</p>
        <p className="sec1_description">
          Events in the political sphere serve as a significant pillar for any
          democratic nation. Whether you're planning a party, a conference, or a
          fundraising event, we offer everything you could possibly require to
          pull off a spectacular gathering.
        </p>
      </div>
      <div className="section2_main_container">
        <Grid container className="section_2">
          <Grid item lg={6} className="left">
            <img
              src={require("../assets/images/political_events_section_image.png")}
            />
          </Grid>
          <Grid item lg={5} className="right">
            <p className="sec2_subtitle">The Venue</p>
            <p className="sec2_description">
              With big venues to hold your supporters by the thousand, Gayatri
              Vihar is equipped to handle crowds of any size to ensure that your
              event happens without a hitch.
            </p>
          </Grid>
        </Grid>
      </div>

      <div className="catering_footer_card">
        <h3>Dream Event</h3>
        <p className="card_title">START PLANNING</p>
        <p className="card_subtitle">
          Let's talk about your
          <br /> dream event
        </p>
        <NavLink to="/contact-hall">CONTACT US</NavLink>
      </div>
    </div>
  );
}

export default PoliticalEvents;
