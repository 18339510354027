import React, { useEffect, useState } from "react";
import "../assets/css/gallery.css";
import { Grid } from "@mui/material";
import MetaTags from 'react-meta-tags';

function Gallery() {
  const CategoryList = [
    {
      category: "All Photos",
    },
    {
      category: "Garden",
    },
    {
      category: "Venue",
    },
    {
      category: "Decor",
    },
    {
      category: "Catering",
    },
  ];

  const GalleryImages = [
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "large",
    },
  ];

  const [catActive, setCatActive] = useState("All Photos");
  const [categoryItems, setCategoryItems] = useState(GalleryImages);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleCategoryFilter();
  }, []);

  const handleCategoryFilter = () => {
    const categoryItemsResult = categoryItems.filter(
      (item) => item.imgCategory === catActive || catActive === "All Photos"
    );
    setCategoryItems(categoryItemsResult);
    console.log(categoryItems);
  };

  return (
    <div className="gallery_page">
      <MetaTags>
        <title>The Collection - Gayatri Vihar Gallery</title>
        <meta name="description" content="Explore images of Garden, venue, decor and caterings - one of the best Luxury Wedding Venue & Banquet Hall in Bangalore!" />
        <meta property="og:title" content="The Collection - Gayatri Vihar Gallery" />
      </MetaTags>
      <div container className="div_cont">
        <div className="div_header">
          <p className="div_hdr_p1">Gayatri Vihar Gallery</p>
          <p className="div_hdr_p2">The Collection</p>
        </div>

        <ul className="category_list">
          {CategoryList.map((catItem, index) => {
            return (
              <li
                key={index}
                className={`${
                  catActive === catItem.category
                    ? "category_item_active"
                    : "category_item"
                }`}
                onClick={() => {
                  setCatActive(catItem.category);
                }}
              >
                {catItem.category}
              </li>
            );
          })}
        </ul>

        <Grid container className="gallery_grid_container">
          {categoryItems.map((items, index) => {
            const { gridLength, image } = items;

            if (catActive === "All Photos" || catActive === items.imgCategory) {
              return (
                <Grid
                  item
                  lg={`${
                    gridLength === "large"
                      ? 11.5
                      : gridLength === "medium"
                      ? 5.5
                      : 3.5
                  }`}
                  xs={12}
                  className={`${
                    gridLength === "small" || gridLength === "medium"
                      ? `${
                          gridLength === "small"
                            ? "gallery_grid_small_item"
                            : "gallery_grid_medium_item"
                        }`
                      : "gallery_grid_item"
                  }`}
                >
                  <img
                    key={index}
                    src={image}
                    alt=""
                    className="gallery_image"
                  />
                </Grid>
              );
            }
          })}
        </Grid>

        {/* <div>
          <div className="button_div">
            <button className="btn btn_1">All Photos</button>
            <button className="btn">Garden</button>
            <button className="btn">Venue</button>
            <button className="btn">Decor</button>
            <button className="btn">Catering</button>
          </div>
        </div> 

        <Grid container>
          <Grid item className="a mob_gallery_grid" lg={5.5} xs={12}></Grid>
          <Grid item className="b mob_gallery_grid" lg={5.5} xs={12}></Grid>
          <Grid item className="c mob_gallery_grid" lg={11.5} xs={12}></Grid>
          <Grid item className="d mob_gallery_grid" lg={11.5} xs={12}></Grid>

          <Grid item className="e mob_gallery_grid" lg={3.5} xs={12}></Grid>
          <Grid item className="f mob_gallery_grid" lg={3.5} xs={12}></Grid>
          <Grid item className="g mob_gallery_grid" lg={3.5} xs={12}></Grid>
          <Grid item className="h mob_gallery_grid" lg={5.5} xs={12}></Grid>
          <Grid item className="i mob_gallery_grid" lg={5.5} xs={12}></Grid>

          <Grid item className="e mob_gallery_grid" lg={3.5} xs={12}></Grid>
          <Grid item className="f mob_gallery_grid" lg={3.5} xs={12}></Grid>
          <Grid item className="g mob_gallery_grid" lg={3.5} xs={12}></Grid>
          <Grid item className="c mob_gallery_grid" lg={11.5} xs={12}></Grid>
        </Grid>*/}
      </div>
    </div>
  );
}

export default Gallery;
