import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import "../assets/css/chinese.css";

function Western() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Chinese_page">
      <div className="western_hero_banner">WESTERN CUISINE</div>

      <Grid container>
        <Grid item lg={3} xs={3} className="cuisine_grid_frame">
          <img
            src={require("../assets/images/cuisine_left_frame.png")}
            alt="About_us"
            className="cuisine_left_frame"
          />
        </Grid>
        <Grid item lg={6} xs={6} className="cuisine_grid_center">
          <p className="cuisine_p1">Savor Every Bite</p>
          <h2>The Taste of the West</h2>
          <Grid className="cuisine_p2">
          The perfect destination for all sorts of events. Be it large weddings or small ones, corporate events or private parties, we have the perfect venues to host all of these at Gayathri Vihar. The best part, we have cuisines from around the world to make it more memorable. From contemporary Indian to lavish western, anything you want catered to your taste
          </Grid>
        </Grid>
        <Grid item lg={3} xs={3} className="cuisine_grid_right">
          <img
            src={require("../assets/images/cuisine_right_frame.png")}
            alt="About_us"
            className="cuisine_right_frame"
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item lg={12} className="cuisine_footer">
          <h3>Start Planning</h3>
          <p>Let’s talk about your dream event</p>
          <NavLink to="/contact-catering">ENQUIRE NOW</NavLink>
        </Grid>
      </Grid>
    </div>
  );
}

export default Western;
