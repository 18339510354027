import React, { useEffect } from "react";
import "../assets/css/chinese.css";
import { Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import MetaTags from 'react-meta-tags';

function Chinese() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Chinese_page">
      <div className="chinese_hero_banner">CHINESE CUISINE</div>

      <Grid container>
        <Grid item lg={3} xs={3} className="cuisine_grid_frame">
          <img
            src={require("../assets/images/cuisine_left_frame.png")}
            alt="About_us"
            className="cuisine_left_frame"
          />
        </Grid>
        <Grid item lg={6} xs={6} className="cuisine_grid_center">
          <p className="cuisine_p1">Savor Every Bite</p>
          <h2>The Taste of Oriental Food</h2>
          <Grid className="cuisine_p2">
          In a country where the traditional way to say hello is "have you eaten yet?" (ni chile ma), you can be sure that the food is amazing. China has the most well-known food culture in the whole world. Sagar brings the best of the Chinese Cuisine directly to your plates.
          </Grid>
        </Grid>
        <Grid item lg={3} xs={3} className="cuisine_grid_right">
          <img
            src={require("../assets/images/cuisine_right_frame.png")}
            alt="About_us"
            className="cuisine_right_frame"
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item lg={12} className="cuisine_footer">
          <h3>Start Planning</h3>
          <p>Let’s talk about your dream event</p>

          <NavLink to="/contact-catering">ENQUIRE NOW</NavLink>
        </Grid>
      </Grid>
    </div>
  );
}

export default Chinese;
