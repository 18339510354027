import React, { useEffect } from "react";
import "../assets/css/cuisine.css";
import { Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import MetaTags from 'react-meta-tags';

function Cuisine() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="cuisine_page">
      <div item className="cuisine_hero_img" lg={12}>
        <p className="header_text">INDIAN CUISINE</p>
      </div>
      <Grid container>
        <Grid item lg={3} xs={3} className="cuisine_grid_frame">
          <img
            src={require("../assets/images/cuisine_left_frame.png")}
            alt="About_us"
            className="cuisine_left_frame"
          />
        </Grid>
        <Grid item lg={6} xs={6} className="cuisine_grid_center">
          <p className="cuisine_p1">Savor every bite</p>
          <h2>The Taste of 29 States</h2>
          <Grid className="cuisine_p2">
            From Kashmir to Kanyakumari, India has more than 140 million people,
            29 states, and a lot of different cultures, India is as diverse in
            its food as it is in its people. Sagar Caterers prepares authentic
            Indian cuisine using locally-sourced spices.
          </Grid>
        </Grid>
        <Grid item lg={3} xs={3} className="cuisine_grid_right">
          <img
            src={require("../assets/images/cuisine_right_frame.png")}
            alt="About_us"
            className="cuisine_right_frame"
          />
        </Grid>
      </Grid>

      <div container className="div_cont">
        <Grid container lg={12} spacing={1} className="cont">
          <Grid item lg={6} xs={12}>
            <img
              src={require("../assets/images/cuisine_south.png")}
              alt="About_us"
              className="cuisine_se_img"
            />
          </Grid>
          <Grid item lg={6} xs={12} className="cuisine_south_text">
            <h4 className="cuisine_se_header">South Indian</h4>
            <p className="cuisine_se_p">
              Be it Tamil Saapadu, or Malayali Sadhya, or Telugu Thali, or
              Kannada Oota, we’ve got you covered, made from authentic South
              Indian spices.
            </p>
          </Grid>

          <Grid className="cuisine_north">
            <Grid item lg={6} xs={12} className="cuisine_north_text">
              <h4 className="cuisine_nw_header">North Indian</h4>
              <p className="cuisine_nw_p">
                Rich aromatic gravies, spicy vegetable stir-fries, moist and
                tender slow-cooked meat, and sinful desserts, each dish created
                to show off the unique and different flavours of North India.
              </p>
            </Grid>
            <Grid item lg={6} xs={12}>
              <img
                src={require("../assets/images/cuisine_north.png")}
                alt="About_us"
                className="cuisine_nw_img"
              />
            </Grid>
          </Grid>

          <Grid item lg={6} xs={12}>
            <img
              src={require("../assets/images/cuisine_east.png")}
              alt="About_us"
              className="cuisine_se_img"
            />
          </Grid>

          <Grid item lg={6} xs={12} className="cuisine_east_text">
            <h4 className="cuisine_se_header">East Indian</h4>
            <p className="cuisine_se_p">
              From dalma, litti chokha, fish curries to momos and thukpa,
              authentic East Indian food that makes you feel like you were
              transported back there.
            </p>
          </Grid>

          <Grid className="cuisine_west">
            <Grid item lg={6} xs={12} className="cuisine_west_text">
              <h4 className="cuisine_nw_header">West Indian</h4>
              <p className="cuisine_nw_p">
                Mouthwatering Maharashtrian bhel puris, Gujarati dhoklas,
                Rajasthani Bati choorma and Goan vindaloo, whatever it is we
                have got you covered.
              </p>
            </Grid>

            <Grid item lg={6} xs={12}>
              <img
                src={require("../assets/images/cuisine_west.png")}
                alt="About_us"
                className="cuisine_nw_img"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Grid container>
        <Grid item lg={12} className="cuisine_footer">
          <h3>Start Planning</h3>
          <p>Let’s talk about your dream event</p>

          <NavLink to="/contact-catering">ENQUIRE NOW</NavLink>
        </Grid>
      </Grid>
    </div>
  );
}

export default Cuisine;
