import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import "../assets/css/wedding-events.css";
import { NavLink } from "react-router-dom";
import MetaTags from 'react-meta-tags';

function WeddingEvents() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="wedding_events_conatiner">
      <MetaTags>
        <title>Luxury Wedding Venue in Bangalore - Gayatri Vihar Sagar</title>
        <meta name="description" content="Your dream wedding day is almost here in the heart of Bangalore, from venues large enough to accommodate thousands of people to others that are more intimate and personal." />
        <meta property="og:title" content="Luxury Wedding Venue in Bangalore - Gayatri Vihar Sagar" />
      </MetaTags>
      <div className="wedding_hero_container hero_container">
        <p className="hero_content">Weddings</p>
      </div>
      <div className="section_1">
        <p className="sec1_subtitle">Why Gayatri Vihar</p>
        <p className="sec1_description">
          Your dream wedding day is almost here. We have been at the forefront
          of wedding planning and catering in Bangalore for decades. We’re
          located at the heart of Bangalore at Palace Grounds, giving you that
          royal feel as soon as you step into the corridor. We have the
          experience and the venues to give you the wedding of your dreams. To
          make sure your wedding day goes off without a hitch, we also offer
          wedding planning services.
        </p>
      </div>
      <div className="section2_main_container">
        <Grid container className="section_2">
          <Grid item lg={6} className="left">
            <img
              src={require("../assets/images/wedd_events_section_image.png")}
            />
          </Grid>
          <Grid item lg={5} className="right">
            <p className="sec2_subtitle">The Venue</p>
            <p className="sec2_description">
              Located at the heart of Bangalore at the palace grounds, Gayathri
              Vihar boasts the perfect venues to make your wedding have that
              royal feel. Gayatri Vihar features a diverse range of facilities,
              from venues large enough to accommodate thousands of people to
              others that are more intimate and personal. We provide everything
              you need for a wedding, whether it be a western style or a
              traditional one.
            </p>
          </Grid>
        </Grid>
      </div>

      <div className="catering_footer_card">
        <h3>Dream Event</h3>
        <p className="card_title">START PLANNING</p>
        <p className="card_subtitle">
          Let's talk about your
          <br /> dream event
        </p>
        <NavLink to="/contact-hall">CONTACT US</NavLink>
      </div>
    </div>
  );
}

export default WeddingEvents;
