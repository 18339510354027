import React from "react";
import "../assets/css/albumPhotos.css";
import { Grid } from "@mui/material";
import MetaTags from 'react-meta-tags';

function AlbumPhotos() {
  const GalleryImages = [
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "large",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img1.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img2.png"),
      imgCategory: "Garden",
      gridLength: "small",
    },
    {
      image: require("../assets/images/Blogs_img3.png"),
      imgCategory: "Venue",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img4.png"),
      imgCategory: "Decor",
      gridLength: "medium",
    },
    {
      image: require("../assets/images/Blogs_img5.png"),
      imgCategory: "Catering",
      gridLength: "large",
    },
  ];

  return (
    <div className="album_photos">
      <div className="album_photos_hero_container">
        <p className="album_photos_hero_content">Mark + Taniya Wedding</p>
      </div>

      <Grid container className="gallery_grid_container">
        {GalleryImages.map((items, index) => {
          const { gridLength, image } = items;

          return (
            <Grid
              item
              lg={`${
                gridLength === "large"
                  ? 11.5
                  : gridLength === "medium"
                  ? 5.5
                  : 3.5
              }`}
              xs={12}
              className={`${
                gridLength === "small" || gridLength === "medium"
                  ? `${
                      gridLength === "small"
                        ? "gallery_grid_small_item"
                        : "gallery_grid_medium_item"
                    }`
                  : "gallery_grid_item"
              }`}
            >
              <img key={index} src={image} alt="" className="gallery_image" />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default AlbumPhotos;
