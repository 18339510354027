import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import "../assets/css/wedding-events.css";
import { NavLink } from "react-router-dom";
import MetaTags from 'react-meta-tags';

function ReligiousGatherings() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="wedding_events_conatiner">
      <MetaTags>
        <title>Religious Gathering Venue in Bangalore - Gayathri Vihar Sagar</title>
        <meta name="description" content="At Gayatri Vihar, we take great delight in arranging religious festivals of all kinds, according to the cultural and religious values to make sure you have a wonderful day." />
        <meta property="og:title" content="Religious Gathering Venue in Bangalore - Gayathri Vihar Sagar" />
      </MetaTags>
      <div className="religious_hero_container hero_container">
        <p className="hero_content">Religious Gatherings</p>
      </div>
      <div className="section_1">
        <p className="sec1_subtitle">Why Gayatri Vihar</p>
        <p className="sec1_description">
          Celebrating religious holidays is an important aspect of our society.
          And they are also wonderful occasions to get together with your loved
          ones. At Gayatri Vihar, we take great delight in arranging religious
          festivals of all kinds, according to the cultural and religious values
          to make sure you have a wonderful day.
        </p>
      </div>
      <div className="section2_main_container">
        <Grid container className="section_2">
          <Grid item lg={6} className="left">
            <img
              src={require("../assets/images/religious_events_section_image.png")}
            />
          </Grid>
          <Grid item lg={5} className="right">
            <p className="sec2_subtitle">The Venue</p>
            <p className="sec2_description">
              Large halls at Gayatri Vihar can accommodate thousands of guests,
              while more intimate spaces are available for smaller gatherings.
            </p>
          </Grid>
        </Grid>
      </div>

      <div className="catering_footer_card">
        <h3>Dream Event</h3>
        <p className="card_title">START PLANNING</p>
        <p className="card_subtitle">
          Let's talk about your
          <br /> dream event
        </p>
        <NavLink to="/contact-hall">CONTACT US</NavLink>
      </div>
    </div>
  );
}

export default ReligiousGatherings;
