import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import "../assets/css/wedding-events.css";
import { NavLink } from "react-router-dom";
import MetaTags from 'react-meta-tags';

function Anniversaries() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="wedding_events_conatiner">
      <MetaTags>
        <title>Wedding Anniversary Venue in Bangalore - Gayathri Vihar Sagar</title>
        <meta name="description" content="Whether you plan on hosting the anniversary celebration for your close friends and family, or whether you want to go the extra mile and celebrate it with everyone at Gayatri Vihar." />
        <meta property="og:title" content="Wedding Anniversary Venue in Bangalore - Gayathri Vihar Sagar" />
      </MetaTags>
      <div className="anniversary_hero_container hero_container">
        <p className="hero_content">Anniversaries</p>
      </div>
      <div className="section_1">
        <p className="sec1_subtitle">Why Gayatri Vihar</p>
        <p className="sec1_description">
          Anniversaries are a special day, celebrated for the time you spend
          together with your partner. It needs to have that special touch,
          whether you’re celebrating Wooden (5th), Tin (10th), Crystal (15th),
          China (20th), Silver (25th), Pearl (30th), Ruby (40th), Golden (50th),
          or Diamond (60th). We ensure that everything goes according to plan,
          and you and your partner gets the day you deserve.
        </p>
      </div>
      <div className="section2_main_container">
        <Grid container className="section_2">
          <Grid item lg={6} className="left">
            <img
              src={require("../assets/images/anniversary_events_section_image.png")}
            />
          </Grid>
          <Grid item lg={5} className="right">
            <p className="sec2_subtitle">The Venue</p>
            <p className="sec2_description">
              Whether you plan on hosting the anniversary celebration for your
              close friends and family, or whether you want to go the extra mile
              and celebrate it with everyone in your contacts, Gayatri Vihar has
              got your back, with venues ranging from being able to host
              thousands of people to a small group of 50, we have everything
              you’ll need.
            </p>
          </Grid>
        </Grid>
      </div>

      <div className="catering_footer_card">
        <h3>Dream Event</h3>
        <p className="card_title">START PLANNING</p>
        <p className="card_subtitle">
          Let's talk about your
          <br /> dream event
        </p>
        <NavLink to="/contact-hall">CONTACT US</NavLink>
      </div>
    </div>
  );
}

export default Anniversaries;
