import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import "../assets/css/wedding-events.css";
import { NavLink } from "react-router-dom";
import MetaTags from 'react-meta-tags';

function CorporateEvents() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="wedding_events_conatiner">
      <MetaTags>
        <title>Conference Hall and Meeting Rooms for Corporate Events in Bangalore - Gaytri Vihar Sagar</title>
        <meta name="description" content="Enjoy the flexible venues here to host a party of anywhere from a 100 to 2000 people without a hassle. Gayatri Vihar at Palace Grounds is conveniently situated near all of Bangalore's tech hubs and is accessible from anywhere in the city." />
        <meta property="og:title" content="Conference Hall and Meeting Rooms for Corporate Events in Bangalore - Gaytri Vihar Sagar" />
      </MetaTags>
      <div className="corporate_hero_container hero_container">
        <p className="hero_content">Corporate Events</p>
      </div>
      <div className="section_1">
        <p className="sec1_subtitle">Why Gayatri Vihar</p>
        <p className="sec1_description">
          When holding a corporate event, first thing you need is
          professionalism in everyone present, from the wait staff to the
          security. Whether it's an award show, a new product launch, or a
          success party, you can trust that your event is in capable hands with
          our team. Food is one less thing to worry about when you have Sagar
          Caterers, Bengaluru's best catering service, on the job.
        </p>
      </div>
      <div className="section2_main_container">
        <Grid container className="section_2">
          <Grid item lg={6} className="left">
            <img
              src={require("../assets/images/corporate_events_section_image.png")}
            />
          </Grid>
          <Grid item lg={5} className="right">
            <p className="sec2_subtitle">The Venue</p>
            <p className="sec2_description">
              Enjoy the flexible venues here to host a party of anywhere from a
              100 to 2000 people without a hassle. Gayatri Vihar at Palace
              Grounds is conveniently situated near all of Bengaluru's tech hubs
              and is accessible from anywhere in the city.
            </p>
          </Grid>
        </Grid>
      </div>

      <div className="catering_footer_card">
        <h3>Dream Event</h3>
        <p className="card_title">START PLANNING</p>
        <p className="card_subtitle">
          Let's talk about your
          <br /> dream event
        </p>
        <NavLink to="/contact-hall">CONTACT US</NavLink>
      </div>
    </div>
  );
}

export default CorporateEvents;
