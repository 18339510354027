import React, { useEffect } from "react";
import "../assets/css/about.css";
import { Grid } from "@mui/material";
import MetaTags from 'react-meta-tags';

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="About_page">
      <MetaTags>
        <title>About us - The story of Gayathri Vihar</title>
        <meta name="description" content="Be it large weddings or small ones, corporate events or private parties, we have the perfect venues to host all of these at Gayathri Vihar." />
        <meta property="og:title" content="About us - The story of Gayathri Vihar" />
      </MetaTags>
      <div>
        <div className="about">
          <div className="about_hero_content">
            {" "}
            <p className="about_us_p">About Us</p>
            <h3>The Story of Gayatri Vihar</h3>
          </div>
        </div>
      </div>
      <Grid container className="about_section_1">
        <Grid item lg={5} xs={12} className="about_lg">
          <p className="about_text">WEDDINGS AND EVENTS</p>

          <img
            src={require("../assets/images/About_us.png")}
            alt="About_us"
            className="About_us_img"
          />
        </Grid>

        <Grid item lg={7} xs={12} className="about_p">
          <p className="about_p1">
            Located at the Heart of Bangalore, filled with the ambience of
            Royalty in Palace grounds
          </p>
          <p className="about_p2">
            The perfect destination for all sorts of events. Be it large
            weddings or small ones, corporate events or private parties, we have
            the perfect venues to host all of these at Gayathri Vihar. The best
            part, we have cuisines from around the world bout_usto make it more
            memorable. From contemporary Indian to lavish western, anything you 
            want catered to your taste.
          </p>

          <img
            src={require("../assets/images/ABOUT_BORDR.png")}
            alt=""
            className="about_border"
          />
        </Grid>
      </Grid>
      <div className="sec_2nd">
        <Grid container className="section_second">
          <div container className="second_grid_sec">
            <Grid item lg={7} xs={12} className="About_section2">
              <div className="">
                <p className="about_section_2p">
                  A Mumbai Man’s Journey: from nimbu-pani to catering at the
                  Bangalore Palace
                </p>
                <p className="about_section_2p1">
                  A simple man who entered the hospitality industry 40 years
                  ago, with the aim to make it big and have a name which would
                  make the eyebrows move up. Learning through mistakes and
                  taking one step at a time, one has made sure to never
                  compromise on any thing and ensured every event is a grand
                  success in its own kind.
                  <a href="" className="about_2p1_b">
                    {" "}
                    READ MORE
                  </a>
                </p>
              </div>
              <div>
                <p className="about_name">
                  Pankaj Kothari
                  <br />
                  <span className="about_span">PROPRIETOR</span>
                </p>
              </div>
            </Grid>
            <Grid item lg={5} xs={12} className="About_section21">
              <img
                src={require("../assets/images/About_person.png")}
                alt=""
                className="about_person_img"
              />
            </Grid>
          </div>
        </Grid>

        <Grid container className="third_second">
          <div container className="third_grid_sec">
            <Grid item lg={5} xs={12} className="About_section31">
              <img
                src={require("../assets/images/about_img.png")}
                alt=""
                className="about_per_img"
              />
            </Grid>
            <Grid item lg={7} xs={12} className="About_section3">
              <div className="">
                <p className="about_section_3p">
                  From an unexpected meeting to becoming the orchestrator at
                  Gayatri Vihar{" "}
                </p>

                <p className="about_section_3p1">
                  His experiential approach & careful orchestration to create
                  the ultimate experience for the clients set him apart from
                  anyone else & made Gayatri Vihar Sagar what it is today.
                  Hailing from Kolhapur, this man's life changed after meeting
                  the man behind Sagar Caterers, Pankaj Kothari, who made him
                  join his journey. <br /> He carried the skills he hailed from
                  his own business in the food industry, and with his experience
                  with Gayatri Vihar Sagar - he now handles elite and
                  prestigious events in Bangalore.
                </p>
              </div>
              <div>
                <p className="about_sec3_name">
                  Jignesh Hamlai
                  <br />
                  <span className="about_sec3_span">GENERAL MANAGER</span>
                </p>
              </div>
            </Grid>
          </div>
        </Grid>
      </div>
    </div>
  );
}

export default About;
