import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import "../assets/css/wedding-events.css";
import { NavLink } from "react-router-dom";
import MetaTags from 'react-meta-tags';

function BirthdayParties() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="wedding_events_conatiner">
      <MetaTags>
        <title>Birthday Event Venue in Bangalore - Gayatri Vihar Sagar</title>
        <meta name="description" content="Celebrate your birthday like the royals at Palace Grounds, Gayatri Vihar offers wide varieties of venues to ensure that you have a good time on your birthday. And with catering done by Sagar Caterers, you’ll be sure to have the best cakes and food so that your guests leave with their hearts and belly full." />
        <meta property="og:title" content="Birthday Event Venue in Bangalore - Gayatri Vihar Sagar" />
      </MetaTags>
      <div className="bday_hero_container hero_container">
        <p className="hero_content">Birthday Parties</p>
      </div>
      <div className="section_1">
        <p className="sec1_subtitle">Why Gayatri Vihar</p>
        <p className="sec1_description">
          Birthday Parties require a special care that requires a personal touch
          to make it that much more special. Gayatri Vihar offers wide varieties
          of venues to ensure that you have a good time on your birthday. And
          with catering done by Sagar Caterers, you’ll be sure to have the best
          cakes and food so that your guests leave with their hearts and belly
          full.
        </p>
      </div>
      <div className="section2_main_container">
        <Grid container className="section_2">
          <Grid item lg={6} className="left">
            <img
              src={require("../assets/images/bday_events_section_image.png")}
            />
          </Grid>
          <Grid item lg={5} className="right">
            <p className="sec2_subtitle">The Venue</p>
            <p className="sec2_description">
              Birthday Parties is one of the most personal celebrations you can
              have for yourself or a loved one, and it is best held in a small,
              personal setting. We’ve got you covered on that with small venues
              suitable for an intimate party or an extravagant one. Celebrate
              your birthday like the royals at Palace Grounds.
            </p>
          </Grid>
        </Grid>
      </div>

      <div className="catering_footer_card">
        <h3>Dream Event</h3>
        <p className="card_title">START PLANNING</p>
        <p className="card_subtitle">
          Let's talk about your
          <br /> dream event
        </p>
        <NavLink to="/contact-hall">CONTACT US</NavLink>
      </div>
    </div>
  );
}

export default BirthdayParties;
