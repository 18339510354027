import React, { useState } from "react";
import "../assets/css/enquire.css";
import { BsInstagram } from "react-icons/bs";
import { FiFacebook } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";

function EnquireNow({ enquire, setEnquire }) {
  const [closeEnquireAnime, setCloseEnquireAnime] = useState(false);

  return (
    <div
      className={`${
        closeEnquireAnime
          ? "enquire_main_container_close_anime"
          : "enquire_main_container"
      }`}
    >
      <div className="enquire_header">
        <div>
          <NavLink
            to="/"
            onClick={() => {
              setEnquire(false);
            }}
          >
            <img src={require("../../src/assets/images/GV_logo.png")} />
          </NavLink>
        </div>
        <IoCloseOutline
          className="close_btn"
          onClick={() => {
            setCloseEnquireAnime(true);
            setTimeout(() => {
              setEnquire(false);
            }, 700);
          }}
        />
      </div>
      <p className="enquire_text_overlay">YOU ARE ENQUIRING FOR?</p>
      <div className="contact_grid_container">
        <NavLink
          to="/contact-hall"
          className="contact_grid_item_1"
          onClick={() => {
            setEnquire(false);
          }}
        >
          <img src={require("../../src/assets/images/contact_hall.png")} />
          <p>Banquet Hall</p>
        </NavLink>
        <NavLink
          to="/contact-hall"
          className="contact_grid_item_2"
          onClick={() => {
            setEnquire(false);
          }}
        >
          <img src={require("../../src/assets/images/contact_catering.png")} />
          <p>Catering</p>
        </NavLink>
      </div>
      <div className="enquire_footer">
        <div>
          <span>
            <span className="left_text">Phone: </span> +91 6361995615{" "}
          </span>
        </div>
        <div>
          <span>
            <span className="left_text">Address: </span>{" "}
            <a
              href="https://www.google.com/maps/place/Gayatri+Vihar+Sagar+-+Palace+Ground+Bengaluru/@13.0093844,77.5835702,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae164af13335f9:0xfc2dd0c08440c301!8m2!3d13.0093792!4d77.5857589"
              target="_blank"
            >
              Get Directions
            </a>
          </span>
        </div>
        <div>
          <span>
            <span className="left_text">MAIL: </span>{" "}
            <a
              href="mailto:bookings@gayatriviharsagar.in"
              target="_blank"
              className="enquire_footer_mail"
            >
              bookings@gayatriviharsagar.in
            </a>
          </span>
        </div>
        <div className="enquire_social_icons">
          <a href="/">
            <BsInstagram className="enquire_social_icon" />
          </a>
          <a href="/">
            <FiFacebook className="enquire_social_icon" />
          </a>
          <a
            href="https://wa.me/6361995615"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsWhatsapp className="enquire_social_icon" />
          </a>
        </div>  
      </div>
    </div>
  );
}

export default EnquireNow;
