import React, { useEffect } from "react";
import "../assets/css/Blogs.css";
import { Grid } from "@mui/material";
import { ReactComponent as Border_img } from "../../src/assets/images/border_img.svg";
import { ReactComponent as NaReadmoreArrow } from "../assets/images/na_readmore_arrow.svg";
import MetaTags from 'react-meta-tags';

function Blogs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Blogs_div">
      <div className="Blogs_hero_section">Blogs</div>

      <div container className="Blogs_div_container">
        <Grid container className="blogs_grid_container">
          <Grid item lg={4}>
            <img
              src={require("../assets/images/Blogs_img1.png")}
              alt="About_us"
              className="Blogs_img1"
            />
          </Grid>
          <Grid item lg={8} className="Blogs_grid_item">
            <h2>Do you want a Non Traditional Wedding?</h2>
            <p>
              A wedding ring is a piece of jewelry that you will wear for the
              rest of your life. You might feel like you need to conform to the
              standard idea
            </p>
            <div>
              <Border_img className="Blogs_border_img" />
            </div>
            <div className="Blogs_bottom">
              <span className="Blogs_date">August 4,2021</span>
              <a href="blog-article" className="Blogs_read">
                Read more
                <NaReadmoreArrow className="na_readmore_arrow" />
              </a>
            </div>
          </Grid>
        </Grid>
        <Grid container className="blogs_grid_container">
          <Grid item lg={4}>
            <img
              src={require("../assets/images/Blogs_img2.png")}
              alt="About_us"
              className="Blogs_img1"
            />
          </Grid>
          <Grid item lg={8} className="Blogs_grid_item">
            <h2>Do you want a Non Traditional Wedding?</h2>
            <p>
              A wedding ring is a piece of jewelry that you will wear for the
              rest of your life. You might feel like you need to conform to the
              standard idea
            </p>
            <div>
              <Border_img className="Blogs_border_img" />
            </div>
            <div className="Blogs_bottom">
              <span className="Blogs_date">August 4,2021</span>
              <a href="blog-article" className="Blogs_read">
                Read more
                <NaReadmoreArrow className="na_readmore_arrow" />
              </a>
            </div>
          </Grid>
        </Grid>
        <Grid container className="blogs_grid_container">
          <Grid item lg={4}>
            <img
              src={require("../assets/images/Blogs_img3.png")}
              alt="About_us"
              className="Blogs_img1"
            />
          </Grid>
          <Grid item lg={8} className="Blogs_grid_item">
            <h2>Do you want a Non Traditional Wedding?</h2>
            <p>
              A wedding ring is a piece of jewelry that you will wear for the
              rest of your life. You might feel like you need to conform to the
              standard idea
            </p>
            <div>
              <Border_img className="Blogs_border_img" />
            </div>
            <div className="Blogs_bottom">
              <span className="Blogs_date">August 4,2021</span>
              <a href="blog-article" className="Blogs_read">
                Read more
                <NaReadmoreArrow className="na_readmore_arrow" />
              </a>
            </div>
          </Grid>
        </Grid>
        <Grid container className="blogs_grid_container">
          <Grid item lg={4}>
            <img
              src={require("../assets/images/Blogs_img4.png")}
              alt="About_us"
              className="Blogs_img1"
            />
          </Grid>
          <Grid item lg={8} className="Blogs_grid_item">
            <h2>Do you want a Non Traditional Wedding?</h2>
            <p>
              A wedding ring is a piece of jewelry that you will wear for the
              rest of your life. You might feel like you need to conform to the
              standard idea
            </p>
            <div>
              <Border_img className="Blogs_border_img" />
            </div>
            <div className="Blogs_bottom">
              <span className="Blogs_date">August 4,2021</span>
              <a href="blog-article" className="Blogs_read">
                Read more
                <NaReadmoreArrow className="na_readmore_arrow" />
              </a>
            </div>
          </Grid>
        </Grid>
        <Grid container className="blogs_grid_container">
          <Grid item lg={4}>
            <img
              src={require("../assets/images/Blogs_img5.png")}
              alt="About_us"
              className="Blogs_img1"
            />
          </Grid>
          <Grid item lg={8} className="Blogs_grid_item">
            <h2>Do you want a Non Traditional Wedding?</h2>
            <p>
              A wedding ring is a piece of jewelry that you will wear for the
              rest of your life. You might feel like you need to conform to the
              standard idea
            </p>
            <div>
              <Border_img className="Blogs_border_img" />
            </div>
            <div className="Blogs_bottom">
              <span className="Blogs_date">August 4,2021</span>
              <a href="blog-article" className="Blogs_read">
                Read more
                <NaReadmoreArrow className="na_readmore_arrow" />
              </a>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Blogs;
