import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../assets/css/catering.css";
import { ReactComponent as Catering_bg_image1 } from "../assets/images/catering_bg_image1.svg";
import { ReactComponent as Catering_bg_image2 } from "../assets/images/catering_bg_image2.svg";

import { ReactComponent as EventSvgLeft } from "../assets/images/events_svg_1.svg";
import { ReactComponent as EventSvgRight } from "../assets/images/events_svg_2.svg";
import { ReactComponent as Cuisine_arrow } from "../assets/images/cuisine_arrow.svg";
import { NavLink } from "react-router-dom";
import { Fade } from "react-reveal";
import MetaTags from 'react-meta-tags';

function Catering() {
  const [content, setContent] = useState(false);
  const [animateContent, setAnimateContent] = useState(false);
  const [active, setActive] = useState(null);

  const cateringEvents = [
    {
      image: require("../assets/images/catering_wedding_event_image.png"),
      title: "Weddings",
      subtitle: "Catering",
      description:
        "The best meal can ensure that guests leave a wedding with their heart and stomach filled. Here at Sagar, we go to great lengths to ensure that every dish is both delicious and well suited to your taste.",
    },
    {
      image: require("../assets/images/catering_corporate_event_image.png"),
      title: "Corporate Events",
      subtitle: "Catering",
      description:
        "Be it corporate parties or pantry service management we’ve got you covered serving the best.",
    },
    {
      image: require("../assets/images/catering_birthday_event_image.png"),
      title: "Birthday Events",
      subtitle: "Catering",
      description:
        "Celebrating a birthday? Wondering about what to serve your guests other than cake. We’ve got you covered, from delicious cakes to tasty foods and drinks, to make the birthday that much more special.",
    },
    {
      image: require("../assets/images/catering_religious_event_image.png"),
      title: "Religious Gatherings",
      subtitle: "Catering",
      description:
        "Religious events are a big part of our culture, and when it comes to these events, large gatherings are sure to ensue. We prepare the food fit for your gatherings according to your cultural beliefs.",
    },
    {
      image: require("../assets/images/catering_political_event_image.png"),
      title: "Political Events",
      subtitle: "Catering",
      description:
        "Expertise in both the public and private sectors guarantees that your event, no matter the size - 80 or 800 guests, will have exceptional food, carefully curated beverages, and a memorable experience.",
    },
    {
      image: require("../assets/images/catering_anniversary_event_image.png"),
      title: "Anniversaries",
      subtitle: "Catering",
      description:
        "Celebrating being together all these years? Want to throw a special party for your significant other?.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="catering">
      <MetaTags>
        <title>Best Catering Services Provider in Bangalore - Gayatri Vihar Sagar</title>
        <meta name="description" content="With 4 decades of experience, Sagar Catering offers Best Catering Services in Bangalore to embrace your momentous occasions. Be it a traditional wedding or a trendy office party, we've got you covered on the catering front." />
        <meta property="og:title" content="Best Catering Services Provider in Bangalore - Gayatri Vihar Sagar" />
      </MetaTags>
      <div className="catering_hero_section">
        <div className="catering_hero_content">
          <h3 className="catering_hero_title">Catering</h3>
          <p className="catering_hero_subtitle">
            With Sagar Caterers, embrace your momentous occasions.
          </p>
        </div>
      </div>
      <div container className="catering_sagar_caters">
        <Catering_bg_image1 className="catering_bg_image1" />
        <Grid container className="catering_sagar_caters_grid">
          <Grid item lg={6}>
            <p className="subtitle">Sagar Catereres</p>
            <p className="para">
              With four decades of legacy to back us, having satisfied the taste
              buds of millions. Be it a traditional wedding or a trendy office
              party, we've got you covered on the catering front. The quality of
              everything that leaves our kitchen is ensured by Mr. Pankaj
              Kothari. We have been pioneers of Bangalore's catering business
              for a long time, earning multiple awards and recognitions,
              including the 2019 Times Iconic Caterer award.
            </p>
          </Grid>
          <Grid item lg={6}>
            <img
              src={require("../assets/images/catering_image1.png")}
              alt="sagar_caterers"
              className="catering_image1"
            />
          </Grid>
        </Grid>
      </div>

      <div className="catering_cuisines_container">
        <Catering_bg_image2 className="catering_bg_image2" />
        <div className="catering_our_cuisines">
          <p className="subtitle">Our Cuisines</p>

          <div className="cuisines_container">
            <div className="cuisine_div">
              <NavLink to="/indian-cuisine" className="cuisine">
                <img
                  src={require("../assets/images/cater_india_image.png")}
                  alt="Indian Cuisine"
                />

                <p className="cat_name">
                  <span>INDIAN</span>{" "}
                  <span>
                    <Cuisine_arrow className="cuisine_arrow" />
                  </span>
                </p>
              </NavLink>{" "}
            </div>
            <div className="cuisine_div">
              <NavLink to="/chinese-cuisine" className="cuisine">
                <img
                  src={require("../assets/images/cater_chinese_image.png")}
                  alt="Chinese Cuisine"
                />
                <p>
                  <span>CHINESE</span>{" "}
                  <span>
                    <Cuisine_arrow className="cuisine_arrow" />
                  </span>
                </p>
              </NavLink>{" "}
            </div>
            <div className="cuisine_div">
              <NavLink to="/western-cuisine" className="cuisine">
                <img
                  src={require("../assets/images/cater_western_image.png")}
                  alt="Western Cuisine"
                />
                <p>
                  <span>WESTERN</span>{" "}
                  <span>
                    <Cuisine_arrow className="cuisine_arrow" />
                  </span>
                </p>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="catering_events">
        <h3 className="title">Its Your Day</h3>
        <p className="subtitle">Make it Memorable</p>
        <Grid container className="catering_events_container">
          {cateringEvents.map((event, index) => {
            const { image, title, subtitle, description, id } = event;
            return (
              <Grid
                key={index}
                item
                lg={5.7}
                xs={12}
                className="wedding_event"
                onMouseOver={() => {
                  setContent(true);
                  setAnimateContent(true);
                  setActive(index);
                  // console.log(active);
                }}
                onMouseLeave={() => {
                  setContent(false);
                  setAnimateContent(false);
                  setActive(null);
                  // console.log(active);
                }}
              >
                <div className="background_gradient"></div>
                <EventSvgLeft className="event_left_svg" />
                <EventSvgRight className="event_right_svg" />
                <img src={image} alt="" />
                <div
                  className={`${
                    animateContent && active === index
                      ? "animate_content content animateBg "
                      : "content"
                  }`}
                >
                  <div className="visible">
                    <h3 className="cursive"> {subtitle} </h3>
                    <p className="title">{title}</p>
                    <div className="hr_line"></div>
                  </div>{" "}
                    <h6
                      className={`${
                        content && active === index
                          ? "description_visible description"
                          : "description"
                      }`}
                    >
                      {description}
                    </h6>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
      <div className="catering_footer_card">
        <h3>Dream Event</h3>
        <p className="card_title">START PLANNING</p>
        <p className="card_subtitle">
          Let's talk about your
          <br /> dream event
        </p>
        <NavLink to="/contact-catering">CONTACT US</NavLink>
      </div>
    </div>
  );
}

export default Catering;
