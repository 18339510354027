import "./App.css";
import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import About from "./views/About";
import Cuisine from "./views/Cuisine";
import Navbar from "./components/Navbar";
import Blogs from "./views/Blogs";
import BlogsArticle from "./views/blogsArticle";
import Gallery from "./views/Gallery";
import VirtualTour from "./views/VirtualTour";
import Chinese from "./views/chinese";

// const express = require('express')
// const bodyParser = require('body-parser');
// const mongoPractice = require('./assets/css/mongodb');

// const app =express();
// app.use(bodyParser.json());
// app.post('/contacthall',mongoPractice.createContact);
// app.listen(3001);




import { ReactComponent as Chat_icon } from "./assets/images/chat.svg";
import Catering from "./views/Catering";
import SagarEvents from "./views/SagarEvents";
import { NavLink } from "react-router-dom";
import ContactCatering from "./views/ContactCatering";
import ContactHall from "./views/ContactHall";
import Collaboration from "./views/Collaboration";
import { useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import { Grid } from "@mui/material";

import FeaturedArticles from "./views/FeaturedArticles";
import EnquireNow from "./components/EnquireNow";
import { ReactComponent as GV_logo } from "../src/assets/images/GV_logo.svg";
import { Fade } from "react-reveal";
import WeddingEvents from "./views/WeddingEvents";
import Western from "./views/Western";
import CorporateEvents from "./views/CorporateEvents";
import BirthdayParties from "./views/BirthdayParties";
import ReligiousGatherings from "./views/ReligiousGatherings";
import PoliticalEvents from "./views/PoliticalEvents";
import Anniversaries from "./views/Anniversaries";
import Album from "./views/Album";
import AlbumPhotos from "./views/AlbumPhotos";

function App() {
  const [chatIcon, setChatIcon] = useState(true);
  const [enquire, setEnquire] = useState(false);

  const Location = useLocation();

  const contact = ["/contact-catering", "/contact-hall"];

  // window.addEventListener("scroll", (event) => {
  //   let scroll = window.scrollY;
  //   if (scroll > 100) {
  //     setChatIcon(true);
  //   } else {
  //     setChatIcon(false);
  //   }
  // });

  return (
    <div className="App">
      <Navbar />
      {chatIcon && !contact.includes(Location.pathname) ? (
        <div className={`${enquire ? "enquire_open" : "enquire_now"}`}>
          <Fade cascade duration={200}>
            <Chat_icon
              className="chat_icon"
              onClick={() => {
                setEnquire(true);
              }}
            />
          </Fade>
        </div>
      ) : (
        ""
      )}
      {enquire ? <EnquireNow enquire={enquire} setEnquire={setEnquire} /> : ""}
      <Routes>
        <Route path="/" element={<Home setEnquire={setEnquire} />} />
        <Route path="/home" element={<Home setEnquire={setEnquire} />} />
        <Route path="/our-legacy" element={<About />} />
        <Route path="/catering" element={<Catering />} />
        <Route path="/events" element={<WeddingEvents />} />
        <Route path="/events/wedding-events" element={<WeddingEvents />} />
        <Route path="/events/corporate-events" element={<CorporateEvents />} />
        <Route path="/events/birthday-events" element={<BirthdayParties />} />
        <Route
          path="/events/religious-gatherings"
          element={<ReligiousGatherings />}
        />
        <Route path="/events/political-events" element={<PoliticalEvents />} />
        <Route path="/events/anniversaries" element={<Anniversaries />} />
        <Route path="/sagar-events" element={<SagarEvents />} />
        <Route path="/contact-catering" element={<ContactCatering />} />
        <Route path="/contact-hall" element={<ContactHall />} />
        <Route path="/collaboration" element={<Collaboration />} />
        <Route path="/indian-cuisine" element={<Cuisine />} />
        <Route path="/chinese-cuisine" element={<Chinese />} />
        <Route path="/western-cuisine" element={<Western />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/BlogsArticle" element={<BlogsArticle />} />
        <Route path="/gallery/images" element={<Gallery />} />
        <Route path="/gallery/album" element={<Album />} />
        <Route path="/gallery/album/album-photos" element={<AlbumPhotos />} />
        <Route path="/gallery/virtual-tour" element={<VirtualTour />} />
        <Route path="/Chinese" element={<Chinese />} />
        <Route path="/featured-articles" element={<FeaturedArticles />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
