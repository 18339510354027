import React, { useState, useEffect } from "react";
import "../assets/css/collaboration.css";
import { Grid } from "@mui/material";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { ReactComponent as Chat_icon } from "../assets/images/chat.svg";
import { NavLink } from "react-router-dom";
import axios from "axios";
import MetaTags from 'react-meta-tags';

function Collabaration() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formValues, setformValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    number: "",
    nameofevent: "",
    date: "",
  });
  const [formErrors, setformErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validated = validate(formValues);
    setformErrors(validated);
    if (Object.keys(validated)?.length === 0) {
      axios
        .post("http://localhost:5000/submit", formValues)
        .then((response) => console.log(response.data));

      setformValues({
        firstname: "",
        lastname: "",
        email: "",
        number: "",
        nameofevent: "",
        date: "",
      });
    }
  };

  var date = new Date();
  var minDate =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

  const validate = (values) => {
    const errors = {};

    const emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.firstname) {
      errors.firstname = " First Name is required";
    }
    if (!values.lastname) {
      errors.lastname = " Last Name is required";
    }
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!emailregex.test(values.email)) {
      errors.email = "email  format 'abcd@domainname.com'";
    }

    if (!values.number) {
      errors.number = "  Mobile Number is required";
    } else if (values.number.length < 10) {
      errors.number = "Number should be 10 digits";
    } else if (values.number.length > 10) {
      errors.number = "Number should be 10 digits";
    }

    if (!values.nameofevent) {
      errors.nameofevent = "Event is required";
    }

    if (!values.date) {
      errors.date = "Date is required";
    }

    return errors;
  };

  return (
    <div className="collaboration">
      <MetaTags>
        <title>Have a Referral for us?</title>
        <meta name="description" content="We are known for our top quality food and perfect arrangements of marriage catering services in Bangalore page." />
        <meta property="og:title" content="Have a Referral for us?" />
      </MetaTags>
      <Grid container className="referral_container">
        <Grid item className="left" lg={4.5} xs={12}>
          <p className="referral_title">
            Have a Referral <br /> for us?{" "}
          </p>
          <p className="referral_content">
            We stand at the top and are best caterers in wedding catering
            services in Karnataka, India. We are known for our top quality food
            and perfect arrangements of marriage catering services in Bangalore
            page.{" "}
          </p>
        </Grid>
        <Grid item className="right" lg={7} xs={12}>
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item lg={6} xs={12} className="input_fields">
                <FormGroup floating>
                  <Input
                    type="text"
                    name="firstname"
                    placeholder="Your First Name"
                    id="input_field"
                    className="collaboration"
                    value={formValues?.firstname || ""}
                    onChange={handleChange}
                  />
                  <p className="collaboration_errors">
                    {formErrors?.firstname}
                  </p>
                  <Label className="label" for="first_name">
                    First Name*
                  </Label>
                </FormGroup>
              </Grid>

              <Grid item lg={6} xs={12} className="input_fields">
                <FormGroup floating>
                  <Input
                    type="text"
                    name="lastname"
                    placeholder="Your Name"
                    id="input_field"
                    className="collaboration"
                    value={formValues?.lastname || ""}
                    onChange={handleChange}
                  />
                  <p className="collaboration_errors">{formErrors?.lastname}</p>

                  <Label className="label" for="first_name">
                    Last Name*
                  </Label>
                </FormGroup>
              </Grid>
              <Grid item lg={6} xs={12} className="input_fields">
                <FormGroup floating>
                  <Input
                    type="number"
                    arrows={false}
                    name="number"
                    placeholder="Your 10 digits Mobile Number"
                    id="input_field"
                    className="collaboration"
                    value={formValues?.number || ""}
                    onChange={handleChange}
                  />
                  <p className="collaboration_errors">
                    {formErrors?.number || ""}
                  </p>

                  <Label className="label" for="first_name">
                    Contact Number*
                  </Label>
                </FormGroup>
              </Grid>
              <Grid item lg={6} xs={12} className="input_fields">
                <FormGroup floating>
                  <Input
                    type="text"
                    name="email"
                    placeholder="Your Email"
                    id="input_field"
                    className="collaboration"
                    value={formValues?.email || ""}
                    onChange={handleChange}
                  />
                  <p className="collaboration_errors">{formErrors?.email}</p>

                  <Label className="label" for="first_name">
                    Mail ID
                  </Label>
                </FormGroup>
              </Grid>
              <Grid item lg={6} xs={12} className="input_fields">
                <FormGroup floating>
                  <Input
                    type="text"
                    name="nameofevent"
                    placeholder="nameofevent"
                    id="input_field"
                    className="collaboration"
                    value={formValues?.nameofevent || ""}
                    onChange={handleChange}
                  />
                  <p className="collaboration_errors">
                    {formErrors?.nameofevent}
                  </p>

                  <Label className="label" for="first_name">
                    Type of Event*
                  </Label>
                </FormGroup>
              </Grid>
              <Grid item lg={6} xs={12} className="input_fields">
                <FormGroup floating>
                  <Input
                    type="date"
                    name="date"
                    placeholder="date"
                    id="input_field"
                    className="collaboration"
                    value={formValues?.date || ""}
                    min={minDate}
                    onChange={handleChange}
                  />
                  <p className="collaboration_errors">{formErrors?.date}</p>

                  <Label className="label" for="first_name">
                    Date of Event*
                  </Label>
                </FormGroup>
              </Grid>

              <button className="submit_btn">SUBMIT</button>
            </Grid>
          </Form>
        </Grid>
      </Grid>
      <div className="why_gayathri_vihar">
        <p className="title">Why Gayatri Vihar</p>
        <div className="caterers_content">
          <div xs={12}>
            <p xs={12} className="subtitle">
              Royalty at Palace Grounds
            </p>
            <p xs={12} className="para">
              Feel the royalty in the presence of the palace grounds.
            </p>
          </div>
          <div>
            <p xs={12} className="subtitle">
              Spacious Venues
            </p>
            <p xs={12} className="para">
              Venues suitable for all kinds of events, decorated to your taste.
            </p>
          </div>
          <div>
            <p xs={12} className="subtitle">
              World Class Food
            </p>
            <p xs={12} className="para">
              Catering service from the world class Sagar Caterers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Collabaration;
