import React, {useEffect} from "react";
import "../assets/css/featuredArticles.css";
import { Grid } from "@mui/material";
import { ReactComponent as NaEndLine } from "../assets/images/na_end_line.svg";
import { NavLink } from "react-router-dom";
import { ReactComponent as NaReadmoreArrow } from "../assets/images/na_readmore_arrow.svg";
import MetaTags from 'react-meta-tags';

function FeaturedArticles() {
  const newsarticles = [
    {
      image: require("../assets/images/n_a_1.png"),
      subtitle: "Bengalureans get into festive mode at Gayatri Vihar",
      description:
        "Nearly 400 members of Federation of Indian Chambers of Commerce and Industry (FICCI) FLO Bengaluru gathered at Gayatri Vihar Hall for...",
    },
    {
      image: require("../assets/images/n_a_2.png"),
      subtitle: "Bengaluru witnesses a dream wedding",
      description:
        "Gayatri Vihar recently hosted the wedding of entrepreneurs Purab and Janvi on February 16",
    },
    {
      image: require("../assets/images/n_a_3.png"),
      subtitle: "City witnesses a grand wedding affair",
      description:
        "Popular hotelier Kurien Varghese’s son, Reuben recently tied the knot with Anju, daughter of John Zachariah...",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="news_articles">
      <MetaTags>
        <title>Explore our Featured News and Articles - Gayathri Vihar Sagar </title>
        <meta name="description" content="The section hosts press releases of important news, events at Gayatri Vihar Sagar" />
        <meta property="og:title" content="Explore our Featured News and Articles - Gayathri Vihar Sagar " />
      </MetaTags>
      <div className="header_section">
        <p className="subtitle">FEATURED ARTICLES</p>
        <p className="title">Explore our Featured News and Articles</p>
      </div>

      {newsarticles.map((article, index) => {
        const { image, subtitle, description } = article;

        return (
          <Grid container key={index} className="news_article_container">
            <Grid item lg={5} xs={12} className="news_article_image">
              <img src={image} alt="news_article_image" />
            </Grid>
            <Grid item lg={7} xs={12} className="news_article_content">
              <p className="news_article_subtitle">{subtitle}</p>
              <p className="news_article_para">{description}</p>
              <NaEndLine className="na_end_line" />
              <NavLink to="/">
                <span>Read more</span>
                <NaReadmoreArrow className="na_readmore_arrow" />
              </NavLink>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
}

export default FeaturedArticles;
