import React,{useEffect} from 'react'
import "../assets/css/blogsArticle.css"
import { Grid } from "@mui/material";
import MetaTags from 'react-meta-tags';

function BlogsArticle() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='BlogsArticle'>
    
     <div Container className='Blogs_Artcl_div_cont'>
      <Grid Container className='Blogs_Artcl_grid_cont'>
       
        <Grid item className='Blogs_Artcl_hero_img'>

        </Grid>
        <Grid item className='Blogs_Artcl_content' lg={12}>
         <h2>Do You want a Non Traditional Wedding </h2>
         <p className='date_hd'>August 4, 2022</p>

         <div className='Blogs_Artcl_content_div'>
            <h2>The header</h2>
            <p>

            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
             Lorem Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled it to 
              make a type specimen book. It has survived not only five centuries, but 
              also the leap into electronic typesetting, remaining essentially unchanged.
               It was popularised in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing software like 
                Aldus PageMaker including versions of Lorem Ipsum.
            </p>

         </div>

         <div className='Blogs_Artcl_content_div'>
            <h2>The header</h2>
            <p>

            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
             Lorem Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled it to 
              make a type specimen book. It has survived not only five centuries, but 
              also the leap into electronic typesetting, remaining essentially unchanged.
               It was popularised in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing software like 
                Aldus PageMaker including versions of Lorem Ipsum.
            </p>

         </div>

         <div className='Blogs_Artcl_content_div'>
            <h2>The header</h2>
            <p>

            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
             Lorem Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled it to 
              make a type specimen book. It has survived not only five centuries, but 
              also the leap into electronic typesetting, remaining essentially unchanged.
               It was popularised in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing software like 
                Aldus PageMaker including versions of Lorem Ipsum.
            </p>

         </div>
</Grid>

      </Grid>
      </div>
    </div>
  )
}

export default BlogsArticle
