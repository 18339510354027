import React, { useEffect, useState } from "react";
import "../assets/css/sagarEvents.css";
import { Grid } from "@mui/material";
import { ReactComponent as Location } from "../assets/images/location.svg";
import { NavLink } from "react-router-dom";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { GrClose } from "react-icons/gr";
import { Form, FormGroup, Input, Label } from "reactstrap";
import axios from "axios";
import MetaTags from 'react-meta-tags';

function SagarEvents() {
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  const [formErrors, setformErrors] = useState({});

  const [formValues, setformValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    number: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validated = validate(formValues);

    setformErrors(validated);
    console.log(formErrors);
    console.log(formValues);

    if (Object.keys(validated)?.length === 0) {
      axios
        .post("http://localhost:5000/submit", formValues)
        .then((response) => console.log(response.data));
      setIsOpen(false);
      setformValues({
        firstname: "",
        lastname: "",
        email: "",
        number: "",
      });
    }
  };

  const validate = (values) => {
    const errors = {};
    const emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values?.firstname) {
      errors.firstname = " First Name is required";
    }

    if (!values?.lastname) {
      errors.lastname = " Last Name is required";
    }

    if (!values?.email) {
      errors.email = "Email is required";
    } else if (!emailregex.test(values.email)) {
      errors.email = "email  format 'abcd@domainname.com'";
    }

    if (!values?.number) {
      errors.number = "  Mobile Number is required";
    } else if (values?.number.length < 10) {
      errors.number = "Number should be 10 digits";
    } else if (values?.number.length > 10) {
      errors.number = "Number should be 10 digits";
    }

    return errors;
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modalIsOpen]);

  // const customStyles = {
  //   content: {
  //     width: "60%",
  //     minHeight: "30%",
  //     padding: "20px",
  //     top: "50%",
  //     left: "50%",
  //     right: "auto",
  //     bottom: "auto",
  //     marginRight: "-50%",
  //     transform: "translate(-50%, -50%)",
  //   },
  // };

  const events = [
    {
      image: require("../assets/images/holi_mobile_sagar_event.png"),
      title: "Wild Holi Bash",
      date: "8 MARCH - 2022",
      time: " From 11 a.m",
      landmark: "Gayatri Vihar Sagar - Palace Ground Bengaluru",
      location:
        "Gayatri Vihar Sagar, Gate No.4, Palace Grounds, Near Mekhri Circle, Bengaluru, Karnataka 560080",
      contact: "6361995615",
      open: "OPEN",
    },
    {
      image: require("../assets/images/ganesh_chaturthi_sagar_event.png"),
      title: "Bangalore Ganesha Utsav",
      date: "31 AUGUST - 2022",
      time: " From 11 a.m",
      landmark: "Gayatri Vihar Sagar - Palace Ground Bengaluru",
      location:
        "Gayatri Vihar Sagar, Gate No.4, Palace Grounds, Near Mekhri Circle, Bengaluru, Karnataka 560080",
      contact: "6361995615",
      open: "OPEN",
    },
    {
      image: require("../assets/images/dandiya_sagar_event.png"),
      title: "Dandiya Night",
      date: "8 OCTOBER - 2022",
      time: " From 7:00pm",
      landmark: "Gayatri Vihar Sagar - Palace Ground Bengaluru",
      location:
        "Gayatri Vihar Sagar, Gate No.4, Palace Grounds, Near Mekhri Circle, Bengaluru, Karnataka 560080",
      contact: "6361995615",
      open: "OPEN",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="sagar_events">
      <MetaTags>
        <title>Upcoming Events in Bangalore by Gayatri Vihar Sagar</title>
        <meta name="description" content="Explore upcoming events in Bangalore ranging from holi bash to dandiya night." />
        <meta property="og:title" content="Upcoming Events in Bangalore by Gayatri Vihar Sagar" />
      </MetaTags>
      <div className="sagar_events_hero_container">
        <div className="sagar_events_hero_content">
          <p>Help Us Plan Your Event</p>
          <h3>We Can't Wait To Hear From You</h3>
        </div>
      </div>
      <div className="discover_events">
        <p className="subtitle">Discover Our Events</p>
        {events.map((event, index) => {
          const { image, title, date, time, landmark, location, contact } =
            event;
          return (
            <div container className="event" key={index}>
              <div className="event_image">
                <img src={event.image} alt="event image" />
              </div>
              <div className="event_details_container">
                <div className="outer_div">
                  <div className="inner_div">
                    <NavLink to="/" className="open_event">
                      {event.open}
                    </NavLink>
                    <div className="event_details">
                      <p className="event_title"> {title} </p>
                      <p className="event_date_time">
                        <span>{date}</span>
                        <span>{time}</span>
                      </p>
                      <p className="event_landmark">
                        <Location className="location_icon" />
                        {landmark}
                      </p>
                      <p className="event_location">{location}</p>
                      <p className="event_contact">
                        CONTACT US:{" "}
                        <span className="contact_no">{contact}</span>
                      </p>
                      <button className="reserve_slot_btn" onClick={openModal}>
                        RESERVE YOUR SLOT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={false}
        onRequestClose={closeModal}
        // style={customStyles}
        className="Modal"
        overlayClassName="overlay"
      >
        <button onClick={closeModal} className="modal_close_btn">
          <GrClose />
        </button>
        <p className="modal_title">Dandiya Night</p>
        <p className="modal_subtitle">
          We will reach out to you shortly after you fill the form
        </p>
        <Form onSubmit={handleSubmit} className="modalForm">
          <Grid container>
            <Grid
              item
              lg={6}
              xs={12}
              className="input_fields sagar_events_modal_inputs "
            >
              <FormGroup floating>
                <Input
                  type="text"
                  name="firstname"
                  placeholder="Your First Name"
                  id="input_field"
                  className="firstname"
                  value={formValues?.firstname || ""}
                  onChange={handleChange}
                />
                <p className="contact_errors">{formErrors?.firstname}</p>
                <Label className="label" for="first_name">
                  First Name*
                </Label>
              </FormGroup>
            </Grid>

            <Grid item lg={6} xs={12} className="input_fields">
              <FormGroup floating>
                <Input
                  type="text"
                  name="lastname"
                  placeholder="Your Last Name"
                  id="input_field"
                  className="lastname"
                  value={formValues?.lastname || ""}
                  onChange={handleChange}
                />
                <p className="contact_errors">{formErrors?.lastname}</p>
                <Label className="label" for="last_name">
                  Last Name*
                </Label>
              </FormGroup>
            </Grid>
            <Grid item lg={6} xs={12} className="input_fields">
              <FormGroup floating>
                <Input
                  type="number"
                  arrows={false}
                  name="number"
                  placeholder="Your 10 digits Mobile Number"
                  id="input_field"
                  className="number"
                  value={formValues?.number || ""}
                  onChange={handleChange}
                />
                <p className="contact_errors">{formErrors?.number}</p>
                <Label className="label" for="contact_name">
                  Contact Number*
                </Label>
              </FormGroup>
            </Grid>
            <Grid item lg={6} xs={12} className="input_fields">
              <FormGroup floating>
                <Input
                  type="text"
                  name="email"
                  placeholder="Your Email"
                  id="input_field"
                  className="email"
                  value={formValues?.email || ""}
                  onChange={handleChange}
                />
                <p className="contact_errors">{formErrors?.email}</p>
                <Label className="label" for="first_name">
                  Mail ID
                </Label>
              </FormGroup>
            </Grid>

            <button className="submit_btn">SUBMIT</button>
          </Grid>
        </Form>
      </Modal>
    </div>
  );
}

export default SagarEvents;
