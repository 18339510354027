import React from "react";
import "../assets/css/footer.css";
import { ReactComponent as GV_logo } from "../../src/assets/images/GV_logo.svg";
import { Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import { BsInstagram } from "react-icons/bs";
import { FiFacebook } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { RiCopyrightLine } from "react-icons/ri";
function Footer() {
  return (
    <div className="footer_main">
      <Grid container className="footer_container">
        <Grid item lg={2} xs={12} className="grid_item">
          <img
            src={require("../assets/images/GV_logo.png")}
            alt="logo"
            className="footer_logo"
          />
        </Grid>
        <Grid item lg={3} xs={12} className="grid_item">
          <h5>ADDRESS</h5>
          <p>
            Gayatri Vihar Sagar, Gate No.4, Palace Grounds, Near Mekhri Circle,
            Bengaluru, Karnataka 560080
          </p>
          <p>
            {" "}
            <a
              href="https://www.google.com/maps/place/Gayatri+Vihar+Sagar+-+Palace+Ground+Bengaluru/@13.0093844,77.5835702,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae164af13335f9:0xfc2dd0c08440c301!8m2!3d13.0093792!4d77.5857589"
              target="_blank"
            >
              <MdLocationOn className="location_icon" /> Show Directions
            </a>{" "}
          </p>
        </Grid>
        <Grid item lg={2.5} xs={12} className="grid_item grid_item2 ">
          <h5>CONTACT</h5>
          <p>+91 6361995615</p>
          <p className="contact_mail">
            {" "}
            <a href="mailto:bookings@gayatriviharsagar.in">
              bookings@gayatriviharsagar.in
            </a>
          </p>
          <div className="social_icons">
            <a href="https://www.instagram.com/gayatrivihar">
              <BsInstagram className="icons" />
            </a>
            <a href="https://www.facebook.com/gayatriviharsagar/">
              <FiFacebook className="icons" />
            </a>
            <a
              href="https://wa.me/6361995615"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsWhatsapp className="icons" />
            </a>
          </div>
        </Grid>
        <Grid item lg={2} xs={12} className=" grid_item_4">
          <h5>
            <NavLink to="/featured-articles">NEWS & ARTICLES</NavLink>
          </h5>
          <h5>
            <NavLink to="/blogs"> BLOGS </NavLink>
          </h5>
        </Grid>
      </Grid>

      <div className="copy_rights">
        <p>
          <span>GAYATRI VIHAR</span>
          <RiCopyrightLine className="copy_right_icon" /> 2022
        </p>
        <p>
          Designed & Developed by <strong>AKOI</strong>
        </p>
      </div>
    </div>
  );
}

export default Footer;
